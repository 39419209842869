// directive > index.js
import Vue from "vue";
import dargWidth from "./dargWidth";

const direvtives: any = {
  dargWidth,
};

for (const key in direvtives) {
  Vue.directive(key, direvtives[key]);
}

export default direvtives;
