// main.js
import directive from "@/directive/index";
import store from "@/store";
import "@/styles/ant-variables.less";
import "@/styles/globel.less";
import Antd from "ant-design-vue";
import PlusUi from "plus-ui-bar";
import "plus-ui-bar/lib/style.css";
import Vue from "vue";
import App from "./App.vue";
import "./permission";
import router from "./router";
Vue.use(Antd);
Vue.use(PlusUi);
Vue.use(directive);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
