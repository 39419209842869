// 默认的名称，没有从后台获取到使用的备用
const menus: any = [
  {
    idx: "/c1",
    meta: {},
    name: "我的应用",
    openType: 1,
    path: "/myapp",
    path0: "/myapp",
  },
  {
    idx: "/c2",
    meta: {},
    name: "通讯录",
    openType: 1,
    path: "/directory",
    path0: "/directory",
  },
  {
    idx: "/c3",
    meta: {},
    name: "企业信息",
    openType: 1,
    path: "/enterpriseinformation",
    path0: "/enterpriseinformation",
  },
];
export default menus;
