// rpath?: 是对路由的一种补偿，在cdn发布后，理论上已经不存在RootPath内容
//         如果在路由上配置了RootPath，就会导致路由错误，所以使用rpath来补偿
const routers = [
  {
    path: "myapp",
    name: "MyApp",
    meta: { hidden: false, title: "我的应用", icon: "appstore" },
    component: () => import("@/views/MyApp/index.vue"),
    children: [
      {
        path: "appconfig",
        name: "AppConfig",
        meta: { hidden: true, title: "应用配置" },
        component: () => import("@/views/MyApp/AppConfig/index.vue"),
      },
    ],
  },
  {
    path: "directory",
    name: "Directory",
    meta: { hidden: false, title: "通讯录", icon: "appstore" },
    component: () => import("@/views/Directory/index.vue"),
  },
  {
    path: "enterpriseinformation",
    name: "EnterpriseInformation",
    meta: { hidden: false, title: "企业信息" },
    component: () => import("@/views/EnterpriseInformation/index.vue"),
  },
];
export default routers;
