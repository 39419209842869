
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  name: "DargBox",
})
export default class extends Vue {
  mousedown() {
    this.$emit("mousedown");
  }
}
