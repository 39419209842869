import DargBox from "@/components/DargBox/index.vue";
import Vue from "vue";
/**
 * 自定义指令v-dargWidth
 * 可接受参数
 * min: 非必传 数字类型 最小宽
 * max: 非必传 数字类型 最大宽
 * dargEnd:非必传 鼠标抬起时的回调函数 形参拖拽后盒子宽度
 * entire : 非必传 布尔值 若传入true 则拖拽整个盒子都可以改变宽度 否则只能拖拽操作柄才有效
 */
export default {
  inserted: function (el: any, binding: any) {
    //父元素相对定位
    el.style.position = "relative";
    //组件mount后append到父元素里面
    const handle: any = Vue.extend(DargBox);
    handle.vm = new handle().$mount();
    el.appendChild(handle.vm.$el);
    const odiv = el;
    //节流开关
    let thriftFlag = true;
    let dargElement = handle.vm.$el;
    //如果传入参数 entire为真 拖动整个元素都可以改变宽度
    if (binding?.value?.entire) {
      dargElement = el;
    }
    dargElement.onmousedown = (e: any) => {
      ondown(e, "mouse");
    };
    dargElement.ontouchstart = (e: any) => {
      ondown(e, "touch");
    };

    function ondown(downE: any, type: string) {
      const elWidth = el.clientWidth;
      let resWidth = elWidth;
      let disX = 0;
      let disY = 0;
      switch (type) {
        case "mouse":
          disX = downE.clientX - odiv.offsetLeft;
          disY = downE.clientY - odiv.offsetTop;
          document.onmousemove = (e: any) => {
            move(e, "mouse");
          };
          document.onmouseup = (e: any) => {
            up(e, "mouse");
          };
          break;
        case "touch":
          disX = downE.touches[0].clientX - odiv.offsetLeft;
          disY = downE.touches[0].clientY - odiv.offsetTop;
          document.ontouchmove = (e: any) => {
            move(e, "touch");
          };
          document.ontouchend = (e: any) => {
            up(e, "touch");
          };
          break;
      }
      return;

      function up(e: any, type: string) {
        if (binding?.value?.dargEnd) {
          binding.value.dargEnd(resWidth);
        }
        document.onmousemove = null;
        document.onmouseup = null;
        document.ontouchmove = null;
        document.ontouchend = null;
      }
      function move(e: any, type: string) {
        if (thriftFlag) {
          thriftFlag = false;
          setTimeout(function () {
            changeWidth(e, type);
            thriftFlag = true;
            //如果流畅度不理想可以调整这里
          }, 10);
        }
      }
      function changeWidth(e: any, type: string) {
        let left = 0;
        let top = 0;
        switch (type) {
          case "mouse":
            left = e.clientX - disX;
            top = e.clientY - disY;
            break;
          case "touch":
            left = e.touches[0].clientX - disX;
            top = e.touches[0].clientY - disY;
            break;
        }
        // console.log("left" + left);
        // console.log("top" + top);
        // 限制X轴最终结果
        const min = binding?.value?.min;
        const max = binding?.value?.max;
        if (min && elWidth + left <= min) {
          resWidth = min;
        } else if (max && elWidth + left > max) {
          resWidth = max;
        } else {
          resWidth = elWidth + left;
        }
        odiv.style.width = resWidth + "px";
        // 限制Y轴最终结果
        // const FHeight = el.parentNode.offsetHeight - el.offsetHeight;
        // if (top <= 0) {
        //   odiv.style.top = 0 + "px";
        // } else if (top >= FHeight) {
        //   odiv.style.top = FHeight + "px";
        // } else if (top > 0) {
        //   odiv.style.top = top + "px";
        // }
      }
    }
  },
};
